.footer {
  background-color: var(--dark);
  font-size: 1rem;
  position: relative;
  bottom: 0;
  width: 100%;
  color: #fff;
  padding: 4rem 1rem;
  z-index: 99999;
  margin-top: 2rem;

  img {
    max-height: 10vw;
    max-width: 50vw;
  }

  .container {
    height: 100%;
    width: 100%;
    max-width: var(--max-width, 1200px);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .brand-images {
    border-bottom: 1px solid var(--gray-250);
    min-height: 40px;
    padding-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .brand-logo,
    .brand-claim {
      margin-top: 0;
      float: none;
    }

    .brand-logo {
      img {
        height: 38px;
      }
    }

    .brand-claim {
      img {
        width: 193px;
      }
    }
  }

  .footer-links {
    .pages-links {
      padding-right: 30px;
      padding-top: 2rem;
      padding-bottom: 2rem;
      display: flex;
      flex-flow: row wrap;
    }

    .copyright {
      p {
        margin-bottom: 0;
        color: var(--gray-300);
      }
    }
  }

  @media (max-width: var(--max-width, 1200px)) {
    .footer-links {
      display: block;

      .pages-links {
        margin-bottom: 30px;
        padding-right: 0px;

        a:last-child {
          margin-right: 0;
        }
      }
    }
  }

  @media (max-width: 800px) {
    font-size: 0.75rem;
    align-items: normal;
    padding: 25px 0;

    .container {
      padding: 0 15px !important;
      align-items: normal !important;
      height: 100% !important;
      justify-content: start;
    }

    .brand-images {
      padding-bottom: 25px;
      min-height: inherit;

      .brand-logo {
        img {
          height: 30px;
        }
      }

      .brand-claim {
        img {
          width: 141px;
        }
      }
    }

    .footer-links {
      flex-grow: 1;

      .pages-links {
        margin-bottom: 0;
        padding: 15px 0;

        a {
          display: inline-block;
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }

      .copyright {
        padding-top: 25px;
        border-top: 1px solid var(--gray-250);
      }
    }
  }
}
