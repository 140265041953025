#pricing--preview {
  position: relative;
  width: 305px;
  min-width: 305px;

  article {
    background-color: #fff;
    width: inherit;
    height:417px;
    border: 1px solid #DDD;
    right:0;
    top: 50px  ;
    overflow: hidden;
  }
  > header {
    span{
      flex:1;
      text-align: center;;
    }
    height: 50px;
    width:100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    background-color: var(--magenta);
    color: #fff;
    font-size: 24px;

    button{
      height: 24px;
      width: 24px;
      margin-left: 8px;
      border: none;
      text-align: center;
      font-weight: bolder;
      font-size: 28px;
      line-height: .8;
      color: var(--magenta);
      background-color: #fff;
      cursor: pointer;
    }

    select{
      height: 30px;
      font-size: 18px;
      background-color: rgba(0,0,0,.4);
      color: #fff;
      border: none;
      margin-right: 6px;
      border-radius: 6px;
    }
  }
}

#print{
  overflow:hidden;
  transform-origin: 0 0;
}

div.paper{
  transform-origin: 0 0;
  font-size: 12px;
  padding: 2em;
  border: 3px dashed #222;
  letter-spacing: 0px;
  display:flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 6em;

  &.printing{
    border:none!important;
    transform: scale(1)!important;
  }
  .value
  {
    padding-left: 1rem;
  }

  .prices{
    flex:1;
    width: 100%;
    position: relative;

    span{
      content: 'vorher';
      font-size: 2.5rem;
      color: var(--primary);
      position: relative;
      font-weight: normal;
      text-transform: lowercase;
      top: 2px;
      left: 1rem;
    }

    h1.value.cross{
      font-size: 9rem;
      letter-spacing: 0;
      position: relative;
      font-weight: 700;
      top: -1rem;
    }

    h1.value{
      font-size: 4.5rem;
      font-weight: 700;
    }

    > h5 {
      font-size:1.5rem;
      position: relative;
      font-weight: 800;
    }
  }

  .oldprice h1.value.cross b{
    position: relative;
    font-weight: 700;
  }
  .oldprice h1.value.cross b:before{
    position: absolute;
    content: " ";
    left: 0;
    width: calc(100% - 4px);
    top: 39%;
    right: -2rem;
    border-top: 10px solid;
    border-color: inherit;
    transform: rotate(-15deg);
  }
  > div img {
    width: 15em;
    height: 15em;
  }

  h1,
  h2,
  h3{
    color: var(--magenta);
    text-transform: uppercase;
    margin:0;
  }

  h1 {
    font-size: 5em;
    color: var(--magenta);
    font-weight: 900;
    letter-spacing: -1px;
  }

  h2 {
    font-size: 4em;
    font-weight: 300;
  }

  h3 {
    font-size: 2em;
    font-weight: 900;
  }

  h5 {
    color: #222;
    font-weight: 900;
    margin-left: 1rem;
    margin-top: 1rem;
  }

  p {
    color: var(--magenta);
    font-size: 1.6em;
    padding-bottom: 1rem;
    padding-left: 12px;
  }

  ul  {
    padding:0;
    padding-left: 2.4rem;
    list-style: none;
  }

  li:before{
    content: ' ';
    background-color: #222;
    width: 8px;
    height: 8px;
    left: -18px;
    top: 12px;
    position: absolute;
  }

  ul + div{
    width: 15em;
  }

  ul  li{
    word-spacing: 2px;
    font-size: 1.5rem;
    line-height: 1.7em;
    white-space: pre-line;
    margin-bottom: 0;
    position: relative;
  }

  footer{
    display:flex;
    width: 100%;
    padding-left: 2rem;
    padding-bottom: 10px;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    > h2{
      font-weight: 600;
      font-size: 2em;
      width: 100%;
      margin-top: 10px;
    }

    > img {
      height:3.8em;
    }
  }
}
