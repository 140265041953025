@import-normalize;

@font-face {
  font-family: TeleNeoWeb;
  font-style: normal;
  font-weight: bold;
  src: url(./fonts/TeleNeoWeb-Bold.eot); /* IE9 Compat Modes */
  src: url(./fonts/TeleNeoWeb-Bold.woff2) format('woff2'),
       url(./fonts/TeleNeoWeb-Bold.woff) format('woff');
}


@font-face {
  font-family: TeleNeoWeb;
  font-style: italic;
  font-weight: bold;
  src: url(./fonts/TeleNeoWeb-BoldItalic.eot); /* IE9 Compat Modes */
  src: url(./fonts/TeleNeoWeb-BoldItalic.woff2) format('woff2'),
        url(./fonts/TeleNeoWeb-BoldItalic.woff) format('woff');
}


@font-face {
  font-family: TeleNeoWeb;
  font-style: normal;
  font-weight: 900;
  src: url(./fonts/TeleNeoWeb-ExtraBold.eot); /* IE9 Compat Modes */
  src: url(./fonts/TeleNeoWeb-ExtraBold.woff2) format('woff2'),
        url(./fonts/TeleNeoWeb-ExtraBold.woff) format('woff');
}


@font-face {
  font-family: TeleNeoWeb;
  font-style: italic;
  font-weight: 900;
  src: url(./fonts/TeleNeoWeb-ExtraBoldItalic.eot); /* IE9 Compat Modes */
  src: url(./fonts/TeleNeoWeb-ExtraBoldItalic.woff2) format('woff2'),
        url(./fonts/TeleNeoWeb-ExtraBoldItalic.woff) format('woff');
}


@font-face {
  font-family: TeleNeoWeb;
  font-style: normal;
  font-weight: 500;
  src: url(./fonts/TeleNeoWeb-Medium.eot); /* IE9 Compat Modes */
  src: url(./fonts/TeleNeoWeb-Medium.woff2) format('woff2'),
        url(./fonts/TeleNeoWeb-Medium.woff) format('woff');
}


@font-face {
  font-family: TeleNeoWeb;
  font-style: italic;
  font-weight: 500;
  src: url(./fonts/TeleNeoWeb-MediumItalic.eot); /* IE9 Compat Modes */
  src: url(./fonts/TeleNeoWeb-MediumItalic.woff2) format('woff2'),
        url(./fonts/TeleNeoWeb-MediumItalic.woff) format('woff');
}


@font-face {
  font-family: TeleNeoWeb;
  font-style: normal;
  font-weight: normal;
  src: url(./fonts/TeleNeoWeb-Regular.eot); /* IE9 Compat Modes */
  src: url(./fonts/TeleNeoWeb-Regular.woff2) format('woff2'),
        url(./fonts/TeleNeoWeb-Regular.woff) format('woff');
}


@font-face {
  font-family: TeleNeoWeb;
  font-style: italic;
  font-weight: normal;
  src: url(./fonts/TeleNeoWeb-RegularItalic.eot); /* IE9 Compat Modes */
  src: url(./fonts/TeleNeoWeb-RegularItalic.woff2) format('woff2'),
        url(./fonts/TeleNeoWeb-RegularItalic.woff) format('woff');
}


@font-face {
  font-family: TeleNeoWeb;
  font-style: normal;
  font-weight: 200;
  src: url(./fonts/TeleNeoWeb-Thin.eot); /* IE9 Compat Modes */
  src: url(./fonts/TeleNeoWeb-Thin.woff2) format('woff2'),
        url(./fonts/TeleNeoWeb-Thin.woff) format('woff');
}


@font-face {
  font-family: TeleNeoWeb;
  font-style: italic;
  font-weight: 200;
  src: url(./fonts/TeleNeoWeb-ThinItalic.eot); /* IE9 Compat Modes */
  src: url(./fonts/TeleNeoWeb-ThinItalic.woff2) format('woff2'),
        url(./fonts/TeleNeoWeb-ThinItalic.woff) format('woff');
}

html, body{
  margin: 0;
  font-family: TeleNeoWeb, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.breadcrumb{
  font: normal normal 100 16px/18px TeleNeoWeb;
  padding-top:2rem;
}

.breadcrumb > span:not(:last-child):after{
  content: " > ";
  width: 2em;
  display: inline-block;
  text-align: center;
}

html,body{
  font-size: 12px;
}

ol,ul{
  padding-left:.8em;

}

li {
  margin-bottom: 1em;
}

h1,h2,h3,h4,h5{
  margin:0;
  margin-bottom: 2rem;
}

h1{
  font-weight: normal;
  font-size: 2rem;
  color: var(--primary, #E20074);
}

h5{
  font-weight: 900;
  font-size: 1.2rem;
  color: var(--dark, #222);
}

button, a{
  cursor: pointer;
  outline: none;
  background-color: transparent;
  border: none;
}
button:disabled{
  box-shadow: none;
  border-color: var(--gray-300,#6c6c6c);
  color: var(--gray-300,#6c6c6c);
  background-color: #fff;
  cursor: not-allowed;
}
footer{
  margin-top:2rem;
  margin-bottom:2rem;
}

.select{
  background-color: var(--gray-200, #ccc);
  display: block;
  padding: 0.5em 1em;
  width: 100%;
  margin-top: 1em;
}